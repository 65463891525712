import { useMemo, useCallback, useEffect } from 'react';

import { DigestEvent } from '@sprigShared/heatmapEvent';
import { ReplayEventType } from '@sprigShared/replays';
import { colors } from 'twig';

import { getXPath, nodeIsElement } from 'utils';

export interface GroupedDigestEvent {
  xpath: string;
  events: DigestEvent[];
  element?: HTMLElement;
}

export const SPRIG_HIGHLIGHT_CLASS_NAME = 'sprig-tooltip-highlight';
const SPRIG_STYLE_ID = 'sprig-style-tag';

const elementIsVisible = (element: HTMLElement, contentWindow: Window) => {
  const computedStyles = contentWindow.getComputedStyle(element);

  // Check basic visibility properties
  if (
    computedStyles.getPropertyValue('visibility') === 'hidden' ||
    computedStyles.getPropertyValue('display') === 'none' ||
    computedStyles.getPropertyValue('opacity') === '0' ||
    (computedStyles.getPropertyValue('height') === '0px' && computedStyles.getPropertyValue('overflow') === 'hidden')
  ) {
    return false;
  }

  // check if element is hidden or is obscured by other elements
  const rect = element.getBoundingClientRect();
  const elementAtPoint = contentWindow.document.elementFromPoint(rect.left, rect.top);

  if (!elementAtPoint) {
    return false;
  }

  return element.contains(elementAtPoint) || elementAtPoint.contains(element);
};

const normalizeEvent = ({
  event,
  foundElementRect,
  scaleAmt,
}: {
  event: DigestEvent;
  foundElementRect: DOMRect;
  scaleAmt?: number;
}) => {
  if (event.type === ReplayEventType.Scroll) {
    const { y, elementAttributes } = event;
    const { targetClientHeight, targetScrollHeight } = elementAttributes;
    if (!targetClientHeight || !targetScrollHeight) return;
    const scrollPercentage = Math.round(((y + targetClientHeight) / targetScrollHeight) * 100);
    event.scrollPercentage = scrollPercentage;
    return event;
  }

  const { left, top, width, height } = event.rect;
  const percentageOfChangeX = (event.x - left) / width;
  const percentageOfChangeY = (event.y - top) / height;
  const newX = Math.round((foundElementRect.width * percentageOfChangeX + foundElementRect.left) * (scaleAmt ?? 1));
  const newY = Math.round((foundElementRect.height * percentageOfChangeY + foundElementRect.top) * (scaleAmt ?? 1));
  event.adjustedX = newX;
  event.adjustedY = newY;
  event.scaleAmt = scaleAmt;
  return event;
};

export const useFoundElements = ({
  events,
  iframe,
  scaleAmt,
  height,
}: {
  events: DigestEvent[];
  iframe?: HTMLIFrameElement;
  scaleAmt?: number;
  height?: number;
}) => {
  useEffect(() => {
    const contentDocument = iframe?.contentDocument;
    if (!contentDocument) {
      return;
    }

    if (contentDocument.getElementById(SPRIG_STYLE_ID)) {
      return;
    }

    const styleTag = contentDocument.createElement('style');
    styleTag.innerText = `.${SPRIG_HIGHLIGHT_CLASS_NAME} { transition:box-shadow 0.2s linear !important; box-shadow: 0px 0px 0px 3px ${colors.turquoise[900]}!important; z-index: 2147483647 !important;}`;
    styleTag.setAttribute('id', SPRIG_STYLE_ID);
    contentDocument.body.appendChild(styleTag);
  }, [iframe, scaleAmt, events, height]);

  const { missingEvents, foundEvents, eventsByXpath } = useMemo(() => {
    const missingEvents: GroupedDigestEvent[] = [];
    const foundEvents: GroupedDigestEvent[] = [];
    const eventsByXpath: Record<
      string,
      { count: number; events: DigestEvent[]; xpath: string; element?: HTMLElement }
    > = {};

    const contentDocument = iframe?.contentDocument;
    const contentWindow = iframe?.contentWindow;

    if (!contentDocument || !events || !contentWindow) {
      return { missingEvents, foundEvents, eventsByXpath };
    }

    events.forEach((event) => {
      if (!eventsByXpath[event.xpath]) {
        eventsByXpath[event.xpath] = {
          count: 0,
          events: [],
          xpath: event.xpath,
          element: undefined,
        };
      }
      eventsByXpath[event.xpath].count += 1;
      eventsByXpath[event.xpath].events.push(event);
    });

    Object.keys(eventsByXpath).forEach((xpath) => {
      const element = contentDocument.evaluate(
        xpath,
        contentDocument,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;

      // we only want to get node's of type element here.
      if (element && nodeIsElement(element) && elementIsVisible(element, contentWindow)) {
        const foundElementRect = element.getBoundingClientRect();
        eventsByXpath[xpath].element = element;
        eventsByXpath[xpath].events.forEach((event) => normalizeEvent({ event, foundElementRect, scaleAmt }));
        foundEvents.push({ ...eventsByXpath[xpath], element });
      } else {
        missingEvents.push({ ...eventsByXpath[xpath] });
      }
    });

    return { missingEvents, foundEvents, eventsByXpath };
  }, [iframe, events, scaleAmt, height]);

  const getElementAtPoint = useCallback(
    (x: number, y: number) => {
      const contentDocument = iframe?.contentDocument;
      if (!contentDocument || (!x && !y)) {
        return;
      }

      const elements = contentDocument.elementsFromPoint(x / (scaleAmt ?? 1), y / (scaleAmt ?? 1));
      for (const element of elements) {
        const data = eventsByXpath[getXPath(element)];
        if (data) {
          return data;
        }
      }
    },
    [eventsByXpath, scaleAmt, iframe]
  );

  return { missingEvents, foundEvents, eventsByXpath, getElementAtPoint };
};
