import { BooleanOperator, Comparator } from '@sprigShared/comparator';
import {
  QuestionTypeID,
  RoutingGroupOption,
  RoutingOption,
  RoutingOptions,
  ScaleLabelType,
} from '@sprigShared/questions';
import { PLATFORM_TYPES, ReplayDurationType, STUDY_TYPES, SurveyProperties } from '@sprigShared/surveys';

import {
  Constraint,
  ConstraintTypeID,
  ENRICH_URL_EMAIL_KEY,
  ENRICH_URL_USER_ID_KEY,
  FEATURES,
  LEGACY_PROPERTY_DEFAULTS,
  LEGACY_REQUIRED_STATUS_BY_TYPE,
  SdkModule,
  URL_MATCH_TYPES,
  WILDCARD_URL_MATCH_TYPES,
} from './constants';
import { EndCard, LiveQuestion } from './questions';

export const getHasNewSkipLogic = ({
  questionTypeId,
  questionIndex,
  routingOptions,
}: {
  questionTypeId: QuestionTypeID;
  questionIndex: number;
  routingOptions: RoutingOptions;
}) => {
  return routingOptions?.some((option) => {
    if (!('group' in option)) return false;

    return option.group.some((r) => {
      if (typeof r === 'number') return r === BooleanOperator.And;
      if (r.questionIndex !== questionIndex) return true;

      switch (questionTypeId) {
        case QuestionTypeID.OpenText:
          return [Comparator.Contains, Comparator.NotContains].includes(r.comparator);
        default:
          return false;
      }
    });
  });
};

export const getQuestionRequiredOrFallback = (question: LiveQuestion) => {
  const required =
    question.values?.properties?.required ?? question.props?.properties?.required ?? question.properties?.required;
  return required ?? LEGACY_REQUIRED_STATUS_BY_TYPE[question.questionTypeId];
};

const hasOtherSpecifyOption = (options: { optionProperties?: { allowsTextEntry?: boolean } }[]) => {
  return options?.find((option) => option.optionProperties?.allowsTextEntry);
};

const hasNoneOfTheAbove = (options: { optionProperties?: { noneOfTheAbove?: boolean } }[]) => {
  return options?.find((option) => option.optionProperties?.noneOfTheAbove);
};

const hasConstraintType = (constraintTypeId: number, constraintArray: { constraintTypeId: number }[]) => {
  return constraintArray?.find((constraint) => constraint.constraintTypeId === constraintTypeId);
};

export const hasExpandedRichText = (props: LiveQuestion['properties']) => {
  return props?.questionHtml || props?.captionHtml;
};

export const hasOpenTextFooter = (props: LiveQuestion['properties']) => {
  return props?.footerHtml;
};

/**
 * Caption text for a question can be customized text or be toggled off. In the case of custom text
 * its value is a string. If it's toggled off, the value is `false`. If it hasn't been customized
 * at all its value is `undefined`.
 *
 * @param {undefined|String|false} captionText
 * @returns Boolean
 */
const isCaptionTextDefault = (captionText?: string) =>
  typeof captionText === 'undefined' ||
  captionText === LEGACY_PROPERTY_DEFAULTS.captionText ||
  captionText === LEGACY_PROPERTY_DEFAULTS.videoCaptionText;

export const isEndCardDefault = (endCard: EndCard) =>
  Boolean(endCard && endCard.headline === 'Thanks for your input!' && !endCard.subheader);

export const isQuestionTypeRequiredBackwardsCompatible = (questionTypeId: QuestionTypeID, required: boolean) => {
  return required === LEGACY_REQUIRED_STATUS_BY_TYPE[questionTypeId];
};

const checkForComparator = (routingOptions: RoutingOption[], target: Comparator, checkNotEqual: boolean = false) => {
  return routingOptions?.some((option: RoutingOption) => {
    // account for legacy skip logic without groups
    if ('comparator' in option) {
      return checkNotEqual ? option.comparator !== target : option.comparator === target;
      // advanced skip logic forms groups, check for skipped within those
    } else if ('group' in option) {
      return option.group.some(
        (groupOption: BooleanOperator | RoutingGroupOption) =>
          typeof groupOption === 'object' &&
          'comparator' in groupOption &&
          (checkNotEqual ? groupOption.comparator !== target : groupOption.comparator === target)
      );
    }
  });
};

type MinConstraint = Pick<Constraint, 'constraintTypeId' | 'value'>;
export const getFeaturesFromSurvey = ({
  constraints = [],
  constraintsByPlatform = {},
  isEditing = false,
  platform,
  questions,
  properties,
}: {
  constraints: MinConstraint[];
  constraintsByPlatform?: {
    [key: string]: { triggers: MinConstraint[]; filters: MinConstraint[]; delay?: { value: number } };
  };
  isEditing?: boolean;
  platform: string;
  questions: LiveQuestion[];
  properties?: SurveyProperties;
}) => {
  const features = new Set<string>([]);
  const lastIndex = questions.length - 1;
  let endCard: EndCard | undefined;

  questions.forEach((q, qIndex) => {
    let properties: LiveQuestion['properties'];
    let options: LiveQuestion['options'] = [];
    const { questionTypeId, values } = q;
    const routingOptions = (values ?? q).routingOptions ?? [];

    // accommodate for different object structures between api and dashboard
    ({ options = [], properties } = values ?? q);
    properties = properties ?? {};

    if (qIndex === 0) {
      endCard = properties.endCard;
    }
    const isLastQuestion = qIndex === lastIndex;

    switch (questionTypeId) {
      case QuestionTypeID.NPS:
        features.add(FEATURES.nps);
        break;
      case QuestionTypeID.RatingScale: {
        const { scaleLabelType } = properties;
        if (scaleLabelType && ['star', 'smiley'].includes(scaleLabelType)) {
          features.add(FEATURES.ratingScale);
        }
        break;
      }
      case QuestionTypeID.MultipleChoice:
        if (hasOtherSpecifyOption(options)) {
          features.add(FEATURES.otherSpecify);
        }
        break;
      case QuestionTypeID.MultipleSelect: {
        if (routingOptions?.length > 0 && checkForComparator(routingOptions, Comparator.Submitted, true)) {
          features.add(FEATURES.mcmsSkipLogic);
        }
        if (hasOtherSpecifyOption(options)) {
          features.add(FEATURES.otherSpecify);
        }

        if (hasNoneOfTheAbove(options)) {
          features.add(FEATURES.noneOfTheAbove);
        }
        break;
      }
      case QuestionTypeID.TextUrlPrompt: {
        features.add(FEATURES.textUrlPrompt);
        const textUrlButtonUrl = properties.buttonUrl || '';
        if (textUrlButtonUrl.includes(ENRICH_URL_USER_ID_KEY) || textUrlButtonUrl.includes(ENRICH_URL_EMAIL_KEY)) {
          features.add(FEATURES.enrichUrl);
        }
        break;
      }
      case QuestionTypeID.ConsentLegal:
        features.add(FEATURES.consentLegal);
        break;
      case QuestionTypeID.VideoVoice:
        features.add(FEATURES.videoVoice);
        if (properties?.hideRecordedPrompt) {
          features.add(FEATURES.hideRecordedPrompt);
        }
        break;
      case QuestionTypeID.RecordedTask:
        features.add(FEATURES.recordedTask);
        break;
      case QuestionTypeID.Matrix: {
        features.add(FEATURES.matrix);
        if (properties.displayMatrixAsAccordion || platform === PLATFORM_TYPES.MOBILE) {
          features.add(FEATURES.mobileAccordionMatrix);
        }
      }
    }
    const { captionText, buttonText, openTextPlaceholder } = properties;
    if (!isCaptionTextDefault(captionText)) features.add(FEATURES.captionText);
    if (openTextPlaceholder && openTextPlaceholder !== LEGACY_PROPERTY_DEFAULTS.openTextPlaceholder)
      features.add(FEATURES.openTextPlaceholder);
    const defaultButtonText = isLastQuestion
      ? LEGACY_PROPERTY_DEFAULTS.finishButtonText
      : LEGACY_PROPERTY_DEFAULTS.buttonText;
    if (buttonText && buttonText !== defaultButtonText) features.add(FEATURES.buttonText);

    if (!isQuestionTypeRequiredBackwardsCompatible(q.questionTypeId, getQuestionRequiredOrFallback(q)))
      features.add(FEATURES.requiredQuestions);

    const hasNewSkipLogic = getHasNewSkipLogic({
      questionIndex: qIndex,
      questionTypeId,
      routingOptions,
    });

    if (hasNewSkipLogic) features.add(FEATURES.advancedSkipLogic);

    if (checkForComparator(routingOptions, Comparator.Skipped)) {
      features.add(FEATURES.skippedSkipLogic);
    }
    if (hasExpandedRichText(properties)) {
      features.add(FEATURES.richText);
    }
    if (hasOpenTextFooter(properties)) {
      features.add(FEATURES.footer);
    }
    if (properties.isDropdown) {
      features.add(FEATURES.multipleChoiceDropdown);
    }
  });

  if (endCard !== undefined && !isEndCardDefault(endCard)) features.add(FEATURES.endCard);

  // During study editing, only the constraintsByPlatform field gets updated with new or removed constraints.
  // The constraints field continues to have the same value until the survey gets saved.
  if (constraintsByPlatform && Object.keys(constraintsByPlatform).length > 0) {
    if (platform === PLATFORM_TYPES.MOBILE && constraintsByPlatform.mobile?.delay?.value)
      features.add(FEATURES.mobileTriggerDelay);

    if (hasConstraintType(ConstraintTypeID.Optimizely, constraintsByPlatform[platform]?.filters?.flat(Infinity)))
      features.add(FEATURES.optimizelyConstraints);

    if (
      hasConstraintType(
        ConstraintTypeID.EventPropertyTrigger,
        constraintsByPlatform[platform]?.triggers?.flat(Infinity)
      )
    )
      features.add(FEATURES.eventProperty);

    if (hasConstraintType(ConstraintTypeID.LaunchDarkly, constraintsByPlatform[platform]?.filters?.flat(Infinity)))
      features.add(FEATURES.launchDarklyConstraints);
  }

  // When viewing survey details, it will iterate through contraints to find the minimum SDK version.
  if (!isEditing) {
    constraints.forEach((constraint) => {
      if (
        constraint.constraintTypeId === ConstraintTypeID.TimeOnPageDelay &&
        constraint.value &&
        Number(constraint.value) > 0 &&
        platform === PLATFORM_TYPES.MOBILE
      )
        features.add(FEATURES.mobileTriggerDelay);

      if (constraint.constraintTypeId === ConstraintTypeID.Optimizely) features.add(FEATURES.optimizelyConstraints);
      if (constraint.constraintTypeId === ConstraintTypeID.EventPropertyTrigger) features.add(FEATURES.eventProperty);
      if (constraint.constraintTypeId === ConstraintTypeID.LaunchDarkly) features.add(FEATURES.launchDarklyConstraints);
    });
  }

  // preview / test a study
  if (properties?.previewDomain) {
    features.add(FEATURES.previewDomain);
  }

  // replay
  if (properties?.replayDurationSeconds) {
    features.add(FEATURES.sessionReplay);
  }
  const { replayDurationType } = properties || {};
  if (
    replayDurationType &&
    [ReplayDurationType.After, ReplayDurationType.BeforeAndAfter].includes(replayDurationType)
  ) {
    features.add(FEATURES.replayDurationType);
  }

  // feedback button
  if (isFeedbackButtonSurvey(properties?.studyType)) {
    features.add(FEATURES.feedbackButton);
  }

  // heatmap
  if (isHeatmapSurvey(properties?.studyType)) {
    features.add(FEATURES.heatmap);
  }

  return features;
};

// got these formatter functions from here
// https://www.codegrepper.com/code-examples/javascript/convert+milliseconds+to+hours+minutes+seconds+javascript
// I've made slight changes to adapt it to our usage scenarios

export function millisecondsToHms(d: number) {
  const sec = Number(d) / 1000;
  const hours = Math.floor(sec / 3600); // get hours
  let minutes: string | number = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds: string | number = Math.floor(sec - hours * 3600 - minutes * 60); //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (minutes && minutes < 10 && hours) {
    minutes = '0' + minutes.toString();
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (hours) {
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
  } else {
    return minutes + ':' + seconds; // MM : SS or 0 : SS
  }
}

export function millisecondsTohms(d: number) {
  const sec = Number(d) / 1000;
  const hours = Math.floor(sec / 3600); // get hours
  const minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  const seconds = (sec - hours * 3600 - minutes * 60).toFixed(1); //  get seconds
  if (hours) {
    return hours + 'h' + minutes + 'm' + seconds + 's'; // Return is HH : MM : SS
  } else if (minutes) {
    return minutes + 'm' + seconds + 's'; // MM : SS or 0 : SS
  } else {
    return seconds + 's';
  }
}

export const isIPSSurvey = (studyType: STUDY_TYPES | undefined) => studyType === STUDY_TYPES.IN_PRODUCT_SURVEY;

export const isFeedbackButtonSurvey = (studyType: STUDY_TYPES | undefined) => studyType === STUDY_TYPES.FEEDBACK_BUTTON;

export const isStandaloneReplaySurvey = (studyType: STUDY_TYPES | undefined) =>
  studyType === STUDY_TYPES.SESSION_REPLAY;

export const isHeatmapSurvey = (studyType: STUDY_TYPES | undefined) => studyType === STUDY_TYPES.HEATMAP;

export const isAlwaysOnReplaySurvey = (studyType?: STUDY_TYPES) => studyType === STUDY_TYPES.ALWAYS_ON_REPLAY;

export const hasReplay = (props: SurveyProperties) => !!props.replayDurationSeconds;

const SMILEY_EMOJIS = [
  {
    idx: 1,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M15.6516 18.7495L18 19C17.8611 17.6667 16.6667 15 13 15C9.33333 15 8.13889 17.6667 8 19L10.3484 18.7495C12.1111 18.5615 13.8889 18.5615 15.6516 18.7495Z" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.5 12.2679L18.2189 11" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <path d="M8 11L10.7189 12.2679" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    </svg>
    `,
  },
  {
    idx: 2,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M15.4876 17.8488L17 18C16.8889 17 15.9333 15 13 15C10.0667 15 9.11111 17 9 18L10.5124 17.8488C12.1667 17.6833 13.8333 17.6833 15.4876 17.8488Z" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.4102 12.0222L18.3079 11.2457" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <path d="M7.91016 11.2457L10.8079 12.0221" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    </svg>
    `,
  },
  {
    idx: 3,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M17 18C16.8889 17 15.9333 15 13 15C10.0667 15 9.11111 17 9 18" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.5 12.0222L18.3978 11.2457" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <path d="M8 11.2457L10.8978 12.0221" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    </svg>
    `,
  },
  {
    idx: 4,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M17 18C16.8889 17 15.9333 15 13 15C10.0667 15 9.11111 17 9 18" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="16" cy="12" r="1" fill="black"/>
    <circle cx="10" cy="12" r="1" fill="black"/>
    </svg>
    `,
  },
  {
    idx: 5,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M18 18L17.9497 17.9497C15.2161 15.2161 10.7839 15.2161 8.05025 17.9497L8 18" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <circle cx="16" cy="12" r="1" fill="black"/>
    <circle cx="10" cy="12" r="1" fill="black"/>
    </svg>
    `,
  },
  {
    idx: 6,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M8 17H13H18" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <circle cx="16" cy="12" r="1" fill="black"/>
    <circle cx="10" cy="12" r="1" fill="black"/>
    </svg>
    `,
  },
  {
    idx: 7,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M8 16L8.05025 16.0503C10.7839 18.7839 15.2161 18.7839 17.9497 16.0503L18 16" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <circle cx="16" cy="12" r="1" fill="black"/>
    <circle cx="10" cy="12" r="1" fill="black"/>
    </svg>
    `,
  },
  {
    idx: 8,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M9 16C9.11111 17 10.0667 19 13 19C15.9333 19 16.8889 17 17 16" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="16" cy="12" r="1" fill="black"/>
    <circle cx="10" cy="12" r="1" fill="black"/>
    </svg>
    `,
  },
  {
    idx: 9,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M9 16C9.11111 17 10.0667 19 13 19C15.9333 19 16.8889 17 17 16" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 12L17.6094 11.7396C16.9376 11.2917 16.0624 11.2917 15.3906 11.7396L15 12" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <path d="M11 12L10.6094 11.7396C9.9376 11.2917 9.0624 11.2917 8.3906 11.7396L8 12" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    </svg>
    `,
  },
  {
    idx: 10,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M10.5124 16.1512L9 16C9.11111 17 10.0667 19 13 19C15.9333 19 16.8889 17 17 16L15.4876 16.1512C13.8333 16.3167 12.1667 16.3167 10.5124 16.1512Z" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 12L17.6094 11.7396C16.9376 11.2917 16.0624 11.2917 15.3906 11.7396L15 12" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <path d="M11 12L10.6094 11.7396C9.9376 11.2917 9.0624 11.2917 8.3906 11.7396L8 12" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    </svg>
    `,
  },
  {
    idx: 11,
    svg: `<svg width="85%" height="85%" viewBox="0 0 26 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" stroke="black" stroke-width="4%"/>
    <path d="M10.1613 15.4756L6 15C6.19444 17 7.86667 21 13 21C18.1333 21 19.8056 17 20 15L15.8387 15.4756C13.9524 15.6912 12.0476 15.6912 10.1613 15.4756Z" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 12L17.6094 11.7396C16.9376 11.2917 16.0624 11.2917 15.3906 11.7396L15 12" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    <path d="M11 12L10.6094 11.7396C9.9376 11.2917 9.0624 11.2917 8.3906 11.7396L8 12" stroke="black" stroke-width="4%" stroke-linecap="round"/>
    </svg>
    `,
  },
];

const STAR = {
  svg: `<svg width="100%" height="100%" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.2596 4.34378C16.5342 3.68826 17.463 3.68826 17.7375 4.34378L20.6418 11.2779C20.7573 11.5537 21.0168 11.7422 21.3147 11.7669L28.807 12.3862C29.5153 12.4448 29.8023 13.3281 29.2637 13.7918L23.5664 18.6967C23.3398 18.8917 23.2407 19.1968 23.3093 19.4878L25.0355 26.8047C25.1987 27.4964 24.4473 28.0423 23.8399 27.6734L17.4145 23.7706C17.1589 23.6154 16.8382 23.6154 16.5827 23.7706L10.1573 27.6734C9.54984 28.0423 8.79846 27.4964 8.96164 26.8047L10.6878 19.4878C10.7565 19.1968 10.6574 18.8917 10.4308 18.6967L4.73348 13.7918C4.19488 13.3281 4.48189 12.4448 5.19017 12.3862L12.6824 11.7669C12.9804 11.7422 13.2398 11.5537 13.3553 11.2779L16.2596 4.34378Z" stroke="black" stroke-width="4%" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
};

const DEFAULT_RATING_RANGE = 5;

export const getRatingIcons = (properties: { range?: string; scaleLabelType?: ScaleLabelType } = {}) => {
  const { range, scaleLabelType } = properties;
  const questionRange = (range && Number(range)) || DEFAULT_RATING_RANGE;
  if (!scaleLabelType || scaleLabelType === ScaleLabelType.Number) {
    return [];
  }
  if (scaleLabelType === ScaleLabelType.Star) {
    return [STAR];
  } else {
    return samples(SMILEY_EMOJIS, questionRange);
  }
};

const samples = <T = unknown>(array: T[], m: number) => {
  if ((m = Math.floor(m)) <= 0) return []; // invalid sampling size
  const n = array.length;
  if (n <= m) return array.slice(); // return everything
  const samples: T[] = [];
  for (let i = 0, d = 2 * m - n; i < n; ++i, d += 2 * m) {
    if (d > 0) {
      samples.push(array[i]);
      d -= 2 * n;
    }
  }
  return samples;
};

export const getRequiredModulesFromSurvey = ({ properties }: { properties: SurveyProperties }) => {
  if (hasReplay(properties) || isHeatmapSurvey(properties.studyType)) return [SdkModule.Replay];
  return [];
};

/**
 * Converts wildcards found in [exactly, contains, startsWith, endsWith] matchType if canConvertWildcards is true. Regardless of canConvertWildcards,
 * legacy events should always convert wildcards to maintain original behavior.
 * @param pattern - URL pattern
 * @param matchType - URL matchType (contains, exactly, etc)
 * @param canConvertWildcards - true if event made after cutoff date
 * @returns
 */
export const convertPatternWithWildcardToRegex = (
  pattern: string,
  canConvertWildcards: boolean,
  matchType?: typeof URL_MATCH_TYPES[keyof typeof URL_MATCH_TYPES]
) => {
  let convertedMatchType = matchType ?? URL_MATCH_TYPES.LEGACY;
  let convertedPattern = pattern;

  if (!WILDCARD_URL_MATCH_TYPES.has(convertedMatchType)) return { convertedMatchType, convertedPattern };

  if ((convertedMatchType === URL_MATCH_TYPES.LEGACY || canConvertWildcards) && pattern?.includes('*')) {
    convertedPattern = pageUrlPatternTransformer(pattern, matchType);
    if (convertedMatchType !== URL_MATCH_TYPES.LEGACY) {
      convertedMatchType = URL_MATCH_TYPES.REGEX;
    }
  }
  return { convertedMatchType, convertedPattern };
};

export const pageUrlPatternTransformer = (pattern: string, matchType = URL_MATCH_TYPES.LEGACY) => {
  let transformedPattern = pattern.replace(/\*/g, '.*');
  if (
    [
      URL_MATCH_TYPES.EXACTLY,
      URL_MATCH_TYPES.CONTAINS,
      URL_MATCH_TYPES.STARTS_WITH,
      URL_MATCH_TYPES.ENDS_WITH,
    ].includes(matchType)
  ) {
    // replace special characters for non-regex patterns, skips over .* sequence to not interfere with wildcards
    transformedPattern = transformedPattern.replace(/(\.(?!\*))|([+?^${}()|[\]\\])/g, '\\$&');
  }
  switch (matchType) {
    case URL_MATCH_TYPES.EXACTLY:
      return `^${transformedPattern}$`;
    case URL_MATCH_TYPES.STARTS_WITH:
      return `^${transformedPattern}`;
    case URL_MATCH_TYPES.ENDS_WITH:
      return `${transformedPattern}$`;
    default:
      return transformedPattern;
  }
};
