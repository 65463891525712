import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import styled from '@emotion/styled';
import clamp from 'lodash/clamp';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useMouseHovered } from 'react-use';

import { ReplayEventType } from '@sprigShared/replays';
import { Icon, size, theme, zIndex, Popup, colors, IconKey } from 'twig';

export interface TimeMarker {
  timestamp: number;
  type: ReplayEventType.RageClick | ReplayEventType.DeadClick;
}

const getMarkerColor = (type: TimeMarker['type']) => {
  switch (type) {
    case ReplayEventType.RageClick:
      return colors.orange[500];
    case ReplayEventType.DeadClick:
      return colors.orange[800];
    default:
      return '';
  }
};

const getMarkerIcon = (type: TimeMarker['type']): IconKey => {
  switch (type) {
    case ReplayEventType.RageClick:
      return 'rage-click';
    case ReplayEventType.DeadClick:
      return 'dead-click';
    default:
      return 'question';
  }
};

interface MarkerWrapperProps {
  timestamp: number;
  totalMillis: number;
}

interface ProgressFilledBarProps {
  percentage: number;
  disableAnimation: boolean;
}

interface MouseTrackerProps {
  xPosition: number;
}

export const TimerProgressBar = ({
  currentMillis,
  totalMillis,
  onSelectTime,
  markers = [],
}: {
  currentMillis: number;
  totalMillis: number;
  onSelectTime: (seconds: number) => void;
  markers?: TimeMarker[];
}) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const lastTime = useRef<number>(0);
  const percentage = clamp(currentMillis / totalMillis, 0, 1);
  const timeChange = currentMillis - lastTime.current;

  useEffect(() => {
    lastTime.current = currentMillis;
  }, [currentMillis]);

  // This is to make the bar snappy. We normally smooth width changes
  // with a css transition. However, when there is a discontinuous time jump
  // this is probably because the user restarted the video, or jumped to a different
  // time. We don't want to smooth this change
  const didTimeJump = timeChange < 0 || timeChange > 600;
  const disableWidthAnimation = didTimeJump;

  const position = useMouseHovered(progressBarRef, { bound: false, whenHovered: true });
  const virtualReference = useMemo(
    () => ({
      getBoundingClientRect: (): DOMRect => {
        const top = (progressBarRef.current?.getBoundingClientRect().top || 0) - 50;
        return new DOMRect(position.docX, top, 0, 0);
      },
    }),
    [position.docX]
  );

  const getTargetTime = useCallback(
    (xPosition: number) => {
      if (!progressBarRef.current) return null;
      const bounds = progressBarRef.current.getBoundingClientRect();
      const positionPercent = clamp((xPosition - bounds.x) / bounds.width, 0, 1);
      return totalMillis * positionPercent;
    },
    [totalMillis]
  );

  const targetTimeLabel = useMemo(
    () => moment.utc(getTargetTime(position.docX)).format('m:ss'),
    [getTargetTime, position.docX]
  );
  const popperElement = useRef(null);
  const { styles, attributes } = usePopper(virtualReference, popperElement.current);
  const portalDiv = document.getElementById('root');
  const isHovered = progressBarRef.current?.matches(':hover');

  return (
    <HoverContainer>
      <ProgressBarLayer
        ref={progressBarRef}
        onClick={(event) => {
          const targetTime = getTargetTime(event.clientX);
          if (targetTime !== null) onSelectTime(targetTime);
        }}
      >
        <BarContainer>
          <ProgressFilledBar
            aria-valuemax={Math.floor(totalMillis / 1000)}
            aria-valuemin={0}
            aria-valuenow={Math.floor(currentMillis / 1000)}
            role="slider"
            percentage={percentage}
            disableAnimation={disableWidthAnimation}
          />
          <MouseTracker xPosition={position.elX - 1} />
        </BarContainer>
      </ProgressBarLayer>

      <MarkerLayer>
        {markers.map((marker, index) => (
          <MarkerWrapper key={`${marker.timestamp}-${index}`} timestamp={marker.timestamp} totalMillis={totalMillis}>
            <Popup
              placement="top"
              modifiers={[
                {
                  name: 'offset',
                  options: { offset: [0, 24] },
                },
              ]}
              trigger={
                <MarkerDot
                  src={getMarkerIcon(marker.type)}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onSelectTime(marker.timestamp);
                  }}
                  type={marker.type}
                />
              }
            >
              <PopupContent>
                <Icon src={getMarkerIcon(marker.type)} />
                <span>{marker.type === ReplayEventType.RageClick ? 'Rage Click' : 'Dead Click'}</span>
                <span>{moment.utc(marker.timestamp).format('m:ss')}</span>
              </PopupContent>
            </Popup>
          </MarkerWrapper>
        ))}
      </MarkerLayer>

      {portalDiv &&
        isHovered &&
        ReactDOM.createPortal(
          <div ref={popperElement} style={styles.popper} {...attributes.popper}>
            <TimeTooltip>
              {targetTimeLabel}
              <Arrow />
            </TimeTooltip>
          </div>,
          portalDiv
        )}
    </HoverContainer>
  );
};

const HoverContainer = styled.div`
  width: 100%;
  height: 10px;
  position: relative;
`;

const MouseTracker = styled.div<MouseTrackerProps>`
  display: none;
  position: absolute;
  background-color: ${theme.navy[100]};
  z-index: ${zIndex.popup};
  width: 3px;
  height: 100%;
  left: ${(props) => `${props.xPosition}px`};
`;

const BarContainer = styled.div`
  transition: all 0.25s ease;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: 2px;
`;

const ProgressBarLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;

  &:hover {
    ${BarContainer} {
      height: ${size(0.75)};
    }
    ${MouseTracker} {
      display: block;
    }
  }
`;

const MarkerLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.popup + 2};
  pointer-events: none;
`;

const ProgressFilledBar = styled.div<ProgressFilledBarProps>`
  background-color: ${theme.black};
  height: 100%;
  width: ${(props) => `${props.percentage * 100}%`};
  transition: ${(props) => (props.disableAnimation ? 'none' : 'width 0.25s linear')};
`;

const MarkerWrapper = styled.div<MarkerWrapperProps>`
  position: absolute;
  transform: translateX(-50%);
  left: ${(props) => `${clamp(props.timestamp / props.totalMillis, 0, 1) * 100}%`};
`;

const MarkerDot = styled(Icon)<{ type: TimeMarker['type'] }>`
  position: relative;
  width: ${size(2.5)};
  height: ${size(2.5)};
  border-radius: 50%;
  background-color: ${(props) => getMarkerColor(props.type)};
  border: 1px solid ${colors.border.light};
  top: ${size(-1)};
  cursor: pointer;
  transition: transform 0.2s ease;
  pointer-events: all;
  padding: ${size(0.25)};

  &:hover {
    transform: scale(1.2);
  }
`;

const TimeTooltip = styled.h6`
  background-color: white;
  border-radius: ${size(1)};
  border: 1px solid ${theme.navy[100]};
  padding: ${size(1.25)} ${size(1.5)};
  font-size: ${size(1.5)};
  filter: drop-shadow(1px 3px 9px rgba(11, 35, 48, 0.1));
  z-index: ${zIndex.popup};
  font-weight: 400;
  margin: 0;
`;

export const Arrow = styled.div`
  background: inherit;
  border-color: inherit;
  visibility: hidden;

  &:before {
    content: '';
    display: block;
    transform: rotate(45deg);
    border-width: 1px;
    border-right-style: solid;
    border-bottom-style: solid;
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: -1;
    bottom: -7px;
    :before {
      transform: rotate(45deg);
    }
    background: inherit;
    border-color: inherit;
    visibility: visible;
    border-bottom-right-radius: 2px;
    left: calc(50% - 6px);
  }
`;

const PopupContent = styled.span`
  color: ${colors.typography.primary};
  font-size: ${size(1.5)};
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: ${size(1)};
`;
