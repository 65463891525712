import React, { useRef, useState, useEffect, useCallback } from 'react';

import styled from '@emotion/styled';
import Color from 'color';
import isEmpty from 'lodash/isEmpty';
import { useEvent, useMeasure } from 'react-use';
import { EventType, Replayer as RrwebReplayer } from 'rrweb';

import { DeviceContainer, HeatmapEmptyState } from '@sprigShared/device-container';
import { environment } from '@sprigShared/environment';
import { DigestEvent } from '@sprigShared/heatmapEvent';
import { BACKDROP_OPTION_DIMENSIONS, DEVICE_TYPE } from '@sprigShared/survey-common-constants';
import { HeatmapType } from '@sprigShared/types-web';
import { colors, LoadingIndicator, size, useResizeContext } from 'twig';

import { useFoundElements, useReplay, useUrlQueryParams } from 'hooks';
import { getMaxScrollDimensions, isDimensions, scrollToTop } from 'utils';

import { Tooltip } from '../Tooltip';

import { DeviceChildrenProps } from './types';

const overlayColor = Color(colors.background.primary).alpha(0.7).hexa();

const HeatmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  & iframe {
    border: none;
    transform-origin: top center;
  }

  & .replayer-wrapper {
    transform-origin: top center;
  }

  & .replayer-mouse {
    display: none;
  }
`;

const Wrapper = styled.div<{ right: number; bottom: number }>`
  position: absolute;
  top: 0;
  left: 0;
  ${({ right, bottom }) => `right: ${right}px; bottom: ${bottom}px;`}
  height: 100%;
  width: 100%;
`;

const SizedDeviceContainer = styled(DeviceContainer)<{ height?: number; maxWidth?: number }>`
  max-height: unset !important;
  height: ${({ height = 700 }) => height}px;
  max-width: ${({ maxWidth = 900 }) => maxWidth}px;
`;

const WidthContainer = styled.div`
  display: flex;
  justify-content: start;
`;

const FocusRect = styled.div<{ scaleAmount: number }>`
  position: absolute;
  pointer-events: none;
  transform-origin: top left;
  ${(p) => `transform: scale(${p.scaleAmount}, ${p.scaleAmount});`}
  box-shadow: 0px 0px 0px ${size(3 / 8)} ${colors.turquoise[900]},
    0px 0px 0px 999999px ${overlayColor};
`;

interface FocusRectInfo {
  top: number;
  left: number;
  height: number;
  width: number;
  borderRadius: string;
}

export const Device = ({
  children,
  isBackdrop = false,
  timestamp,
}: {
  children: (props: DeviceChildrenProps) => React.ReactNode;
  isBackdrop?: boolean;
  timestamp?: string | null;
}) => {
  const { data: replayData, isLoading } = useReplay();
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundWrapperRef = useRef<HTMLDivElement | null>(null);
  const deviceRef = useRef<HTMLDivElement>(null);
  const [focusedElem, setFocusedElem] = useState<HTMLElement | undefined>(undefined);
  const [focusRectInfo, setFocusRectInfo] = useState<FocusRectInfo | null>(null);
  const [replayer, setReplayer] = useState<RrwebReplayer>();
  const [contentHeight, setContentHeight] = useState(700);
  const [events, setEvents] = useState<DigestEvent[]>([]);
  const [scaleAmt, setScaleAmt] = useState(1);
  const [opacity, setOpacity] = useState(100);
  const [hasSessionReplay, setHasSessionReplay] = useState(false);
  const [type, setType] = useState<HeatmapType>(HeatmapType.Heatmap);
  const { isMobile = 'false' } = useUrlQueryParams();
  const { height: windowHeight, width: windowWidth } = useResizeContext();
  const foundElements = useFoundElements({ events, iframe: replayer?.iframe, scaleAmt, height: contentHeight });
  const [backgroundSizeRef, backgroundSize] = useMeasure();
  const [maxDimensions, setMaxDimensions] = useState<{ depth: number; width: number } | null>(null);
  const originalDimensions = useRef<{ height: number; width: number } | null>(null);

  const deviceType = JSON.parse(isMobile) ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.DESKTOP;

  useEffect(() => {
    if (focusedElem) {
      const rect = focusedElem.getBoundingClientRect();
      const style = window.getComputedStyle(focusedElem);
      setFocusRectInfo({
        top: scaleAmt * rect.top,
        left: scaleAmt * rect.left,
        height: rect.height,
        width: rect.width,
        borderRadius: style.borderRadius,
      });
    } else {
      setFocusRectInfo(null);
    }
  }, [focusedElem, scaleAmt]);

  useEffect(() => {
    setFocusRectInfo(null);
  }, [type]);

  const recalcMaxScrollDimensions = (replayer: RrwebReplayer) => {
    if (!originalDimensions.current || !replayer) return;
    const width = replayer.iframe.width;
    const height = replayer.iframe.height;
    try {
      replayer.iframe.height = `${originalDimensions.current.height}px`;
      replayer.iframe.width = `${originalDimensions.current.width}px`;
      const calculatedMax = getMaxScrollDimensions(replayer?.iframe?.contentWindow) || { depth: 0, width: 0 };
      const max = {
        width: Math.max(calculatedMax.width, originalDimensions.current.width),
        depth: Math.max(calculatedMax.depth, originalDimensions.current.height),
      };
      setMaxDimensions((prev) => {
        if (prev?.width === max.width && prev?.depth === max.depth) {
          return prev;
        }
        return max;
      });
    } finally {
      replayer.iframe.height = height;
      replayer.iframe.width = width;
    }
  };

  const adjustScale = useCallback(
    (background?: RrwebReplayer) => {
      if (
        !backgroundWrapperRef.current ||
        !containerRef.current ||
        !deviceRef.current ||
        !background ||
        !maxDimensions ||
        !originalDimensions.current
      ) {
        return;
      }

      let { width: containerWidth } = containerRef.current.getBoundingClientRect();
      if (maxDimensions?.width) {
        containerWidth = Math.min(containerWidth, maxDimensions.width);
      }
      const { width: replayWidth, height: replayHeight } = originalDimensions.current;
      const maxScroll = maxDimensions;
      const newContentHeight = maxScroll.depth || replayHeight;
      const newContentWidth = maxScroll.width || replayWidth;

      const scaleAmtX = containerWidth / (newContentWidth || 1);
      const scaleAmt = scaleAmtX;
      setScaleAmt(scaleAmt);

      backgroundWrapperRef.current.style.transform = `scale(${scaleAmt}, ${scaleAmt})`;
      background.iframe.height = `${newContentHeight}`;
      background.iframe.width = newContentWidth < containerWidth ? `${containerWidth}px` : `${newContentWidth}px`;
      background.iframe.style.display = '';

      const { height: wrapperHeight } = backgroundWrapperRef.current.getBoundingClientRect();
      const firstChild = deviceRef.current.firstChild;

      // ignore the device header
      const { height: deviceFirstChildHeight } =
        firstChild instanceof Element ? firstChild.getBoundingClientRect() : { height: 0 };
      const adjustedHeight = wrapperHeight + deviceFirstChildHeight;
      setContentHeight(adjustedHeight);
      scrollToTop(background.iframe.contentDocument?.documentElement);
    },
    [maxDimensions]
  );

  useEffect(() => {
    adjustScale(replayer);
  }, [replayer, replayData, windowWidth, windowHeight, adjustScale, backgroundSize]);

  useEffect(() => {
    setMaxDimensions(null);
    if (containerRef.current && replayData) {
      const newReplayer = new RrwebReplayer(replayData, {
        root: containerRef.current,
        speed: 0,
        useVirtualDom: false,
        pauseAnimation: false,
        mouseTail: false,
        triggerFocus: false,
      });

      backgroundWrapperRef.current = newReplayer.wrapper;
      backgroundSizeRef(newReplayer.wrapper);
      originalDimensions.current = null;

      setReplayer(newReplayer);

      const initialSize = {
        width: 600,
        height: 400,
      };

      originalDimensions.current = initialSize;
      newReplayer.on('resize', (event) => {
        if (isDimensions(event)) {
          originalDimensions.current = event;
          recalcMaxScrollDimensions(newReplayer);
        }
      });

      // fullsnapshot-rebuilded event gets called when the html page is loaded, but css resources may still be loading
      newReplayer.on('fullsnapshot-rebuilded', () => {
        recalcMaxScrollDimensions(newReplayer);
        setInterval(() => {
          recalcMaxScrollDimensions(newReplayer);
        }, 1500);
      });

      const startTime = replayData?.[0]?.timestamp || 0;
      if (timestamp) {
        newReplayer?.pause?.(Number(timestamp) - startTime);
      } else {
        newReplayer?.pause(startTime);
      }
      return () => {
        newReplayer.destroy();
      };
    }
  }, [backgroundSizeRef, containerRef, replayData, timestamp]);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'heatmap-height-updated',
        // add 20 for the borders
        height: contentHeight + 20,
      },
      environment.appUrl
    );
  }, [contentHeight]);

  useEvent('message', (e: MessageEvent) => {
    switch (e.data?.type) {
      case 'heatmap-events':
        setEvents(e.data?.events || []);
        break;
      case 'heatmap-has-session-replay':
        setHasSessionReplay(e.data?.hasSessionReplay);
        break;
      case 'heatmap-type':
        setType(e.data?.heatmapType);
        break;
      case 'heatmap-opacity':
        setOpacity(e.data?.opacity);
        break;
      default:
        break;
    }
  });

  const pageTitle = replayer?.iframe?.contentDocument?.title;
  useEffect(() => {
    window.parent.postMessage({ type: 'heatmap-page-title', title: pageTitle }, environment.appUrl);
  }, [pageTitle]);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'heatmap-ready',
        ready: true,
      },
      environment.appUrl
    );
    return () => {
      window.parent.postMessage(
        {
          type: 'heatmap-ready',
          ready: false,
        },
        environment.appUrl
      );
    };
  }, []);

  if (isLoading) {
    return (
      <SizedDeviceContainer
        device={deviceType}
        {...(isBackdrop && {
          hideHeaderAndBorder: true,
          height: BACKDROP_OPTION_DIMENSIONS.HEIGHT,
          maxWidth: BACKDROP_OPTION_DIMENSIONS.WIDTH,
        })}
      >
        <LoadingIndicator />
      </SizedDeviceContainer>
    );
  }
  if (!isBackdrop && (isEmpty(replayData) || isEmpty(events))) {
    return <HeatmapEmptyState device={deviceType} mapType={type} />;
  }

  const firstEvent = replayData?.[0];
  const initialWidth = firstEvent?.type === EventType.Meta ? firstEvent.data.width : 0;

  const maxWidth =
    (isBackdrop && 300) || maxDimensions?.width || initialWidth || (deviceType === DEVICE_TYPE.MOBILE ? 300 : 900);

  return (
    <WidthContainer>
      <SizedDeviceContainer
        maxWidth={maxWidth}
        ref={deviceRef}
        height={contentHeight}
        device={deviceType}
        hideHeaderAndBorder={isBackdrop}
      >
        <HeatmapContainer ref={containerRef} />
        <Wrapper bottom={replayer?.iframe?.offsetHeight || 0} right={replayer?.iframe?.offsetWidth || 0}>
          {children({ background: replayer, foundElements, hasSessionReplay, opacity })}
        </Wrapper>
        {focusRectInfo && (
          <FocusRect
            scaleAmount={scaleAmt}
            style={{
              top: focusRectInfo.top,
              left: focusRectInfo.left,
              height: focusRectInfo.height,
              width: focusRectInfo.width,
              borderRadius: focusRectInfo.borderRadius,
            }}
          />
        )}
        {type === HeatmapType.Heatmap && !isBackdrop && (
          <Tooltip
            bottom={replayer?.iframe?.offsetHeight || 0}
            right={replayer?.iframe?.offsetWidth || 0}
            getElementAtPoint={foundElements.getElementAtPoint}
            foundEvents={foundElements.foundEvents}
            hasSessionReplay={hasSessionReplay}
            onFocus={setFocusedElem}
          />
        )}
      </SizedDeviceContainer>
    </WidthContainer>
  );
};
