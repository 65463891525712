import React, { useState, useRef, useEffect, useMemo } from 'react';

import styled from '@emotion/styled';
import Color from 'color';
import uniqueId from 'lodash/uniqueId';
import { Stage, Layer, Circle } from 'react-konva';

import { environment } from '@sprigShared/environment';
import { DigestEvent } from '@sprigShared/heatmapEvent';
import { colors, size, zIndex } from 'twig';

import { DeviceChildrenProps, TooltipContent } from 'components';
import { Title } from 'styles';
import { getTitle } from 'utils/helpers';

const DEFAULT_TOOLTIP_SIZE = 150;

const isNode = (target: EventTarget | null): target is Node => {
  return target instanceof Node;
};

const StyledContainer = styled.div<{ x: number; y: number }>`
  position: absolute;
  background-color: ${colors.common.white};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: ${size(1.25)};
  border: 1px solid hsla(200, 10%, 88%, 1);
  max-width: 500px;
  z-index: ${zIndex.popup};

  ${(p) => (p.y < 0 ? `bottom: ${p.y * -1}px;` : `top: ${p.y}px;`)}
  ${(p) => (p.x < 0 ? `right: ${p.x * -1}px;` : `left: ${p.x}px;`)}
`;

export const Clickmap = ({
  background,
  foundElements: { foundEvents },
  hasSessionReplay,
  opacity,
}: DeviceChildrenProps) => {
  const [clickedEvent, setClickedEvent] = useState<DigestEvent | null>(null);
  const [hoveredEvent, setHoveredEvent] = useState<DigestEvent | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Close the div if clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && isNode(event.target) && !containerRef.current.contains(event.target)) {
      setClickedEvent(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const stats = useMemo(() => {
    return {
      totalSessions: new Set(foundEvents.flatMap(({ events }) => events.map(({ sessionReplayId }) => sessionReplayId)))
        .size,
      totalClicks: foundEvents.flatMap(({ events }) => events).length,
    };
  }, [foundEvents]);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'heatmap-stats-updated',
        stats: stats,
      },
      environment.appUrl
    );
  }, [stats]);

  const renderTooltip = () => {
    if (
      !clickedEvent?.adjustedX ||
      !clickedEvent?.adjustedY ||
      !clickedEvent?.scaleAmt ||
      !background?.iframe?.clientWidth
    )
      return null;
    const title = getTitle({ events: [clickedEvent], xpath: clickedEvent.xpath });

    // If the tooltip is going off the screen, move it to the left
    // DEFAULT_TOOLTIP_SIZE is an arbitary width number because we don't know the size of the tool tip after its rendered
    // Currently the tooltip only contains the title of the xpath so I can't imagine it being more than 150px
    const transformX =
      clickedEvent.adjustedX + DEFAULT_TOOLTIP_SIZE > background?.iframe?.clientWidth * clickedEvent.scaleAmt
        ? clickedEvent.adjustedX - background?.iframe?.clientWidth * clickedEvent.scaleAmt
        : clickedEvent.adjustedX;

    // If the tooltip is going off the on the bottom screen, move the tooltip to display above the clicked event
    const transformY =
      clickedEvent.adjustedY + DEFAULT_TOOLTIP_SIZE > background?.iframe?.clientHeight * clickedEvent.scaleAmt
        ? clickedEvent.adjustedY - background?.iframe?.clientHeight * clickedEvent.scaleAmt
        : clickedEvent.adjustedY;

    return (
      <StyledContainer x={transformX} y={transformY} ref={containerRef}>
        <TooltipContent showReplayButton={hasSessionReplay} uniqueSessionReplayIds={[clickedEvent.sessionReplayId]}>
          <Title>{title}</Title>
        </TooltipContent>
      </StyledContainer>
    );
  };

  return (
    <>
      <Stage height={background?.iframe?.clientHeight} width={background?.iframe?.clientWidth}>
        <Layer>
          {foundEvents.map(({ events }) =>
            events.map((event) => (
              <Circle
                x={event.adjustedX}
                y={event.adjustedY}
                radius={4}
                fill={Color(colors.mango[1000])
                  .alpha(hoveredEvent === event ? 1 : opacity)
                  .hexa()}
                onClick={() => {
                  setClickedEvent(event);
                }}
                key={`${event.adjustedX}.${event.adjustedY}.${event.timestamp}.${event.type}.${uniqueId()}`}
                // hover state
                onMouseEnter={(e) => {
                  const container = e.target.getStage()?.container();
                  if (container) container.style.cursor = 'pointer';
                  setHoveredEvent(event);
                }}
                onMouseLeave={(e) => {
                  const container = e.target.getStage()?.container();
                  if (container) container.style.cursor = 'default';
                  setHoveredEvent(null);
                }}
              />
            ))
          )}
        </Layer>
      </Stage>
      {clickedEvent && renderTooltip()}
    </>
  );
};
