import React, { useEffect, useMemo, useRef, useState } from 'react';

import clamp from 'lodash/clamp';
import { useEvent, useSize } from 'react-use';
import { Replayer as RrwebReplayer } from 'rrweb';

import { environment } from '@sprigShared/environment';
import { ReplayEventType } from '@sprigShared/replays';
import { ReplayerMessage } from '@sprigShared/survey-common-constants';
import { Spinner, theme, Toast } from 'twig';

import { ReplayData } from 'data/replays/types';
import { useFullscreenToggle, useReplay, useUrlQueryParams } from 'hooks/hooks';
import { isDimensions } from 'utils';

import { PlayerContainer, ToolbarContainer, VideoContainer } from './Containers';
import { ReplayerError } from './ReplayerError';
import { ReplayViewerToolbar } from './ReplayViewerToolbar';

import 'rrweb/dist/rrweb.min.css';

export const ReplayViewer = () => {
  const { data: replayData, isError: loadingError, isLoading } = useReplay();
  const showErrorMessage = loadingError || (!isLoading && !replayData);

  if (showErrorMessage) return <ReplayerError showErrorMessage={showErrorMessage} />;
  if (isLoading) return <Spinner size="60px" band="5px" />;
  return <Replayer replayData={replayData} />;
};

interface FrustrationEvent {
  type: ReplayEventType.RageClick | ReplayEventType.DeadClick;
  timestamp: number;
}

const Replayer = ({ replayData }: { replayData: ReplayData | undefined }) => {
  const { t: initialStart } = useUrlQueryParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [player, setPlayer] = useState<RrwebReplayer>();
  const [frustrationEvents, setFrustrationEvents] = useState<FrustrationEvent[]>([]);
  const [replayerContainer, { width, height }] = useSize(<VideoContainer align ref={containerRef} />);
  const { toggle, isFullScreen, fullscreenRef } = useFullscreenToggle();

  const eventMarkers = useMemo(() => {
    if (!replayData || replayData.length === 0 || frustrationEvents.length === 0) return [];

    const startTime = replayData[0].timestamp;

    return frustrationEvents.map((event) => ({
      timestamp: event.timestamp - startTime,
      type: event.type,
    }));
  }, [replayData, frustrationEvents]);

  useEvent('message', (e: MessageEvent<ReplayerMessage>) => {
    if (e.origin === environment.appUrl && player) {
      switch (e.data?.type) {
        case 'getReplayTimestamp':
          window.parent.postMessage(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            {
              type: 'replayTimestamp',
              timestamp: player.getCurrentTime(),
            } as ReplayerMessage,
            environment.appUrl
          );
          break;

        case 'replaySeek':
          player.play(e.data.timestamp);
          break;

        case 'frustrationSignals':
          try {
            const events = JSON.parse(e.data.events);
            setFrustrationEvents(events);
          } catch (error) {
            Toast.error('Oops, something went wrong!');
          }
          break;
      }
    }
  });

  const scaleReplayerToFitContainer = (iframeSize: { width: number; height: number }) => {
    const parent = containerRef.current;
    if (!wrapperRef.current || !parent) return;
    const { width: containerWidth, height: containerHeight } = parent.getBoundingClientRect();
    const { width: replayWidth, height: replayHeight } = iframeSize;
    let scaleAmtX = Math.min(containerWidth / replayWidth, containerHeight / replayHeight);
    if (isNaN(scaleAmtX)) {
      scaleAmtX = 1;
    }
    const scaleAmtY = scaleAmtX;

    wrapperRef.current.style.transform = `scale(${scaleAmtX}, ${scaleAmtY})`;
  };

  useEffect(() => {
    if (containerRef.current && replayData) {
      const replayer = new RrwebReplayer(replayData, {
        root: containerRef.current,
        speed: 1,
        useVirtualDom: false,
        mouseTail: {
          strokeStyle: theme.gold[800],
        },
      });

      // let dashboard know that the replayer is ready
      window.parent.postMessage(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          type: 'replayReady',
          timestamp: replayData[0]?.timestamp || 0,
        } as ReplayerMessage,
        environment.appUrl
      );

      const totalTime = replayer.getMetaData().totalTime;
      const startTime = initialStart ? clamp(+initialStart * 1000, 0, totalTime) : undefined;

      wrapperRef.current = replayer.wrapper;
      setPlayer(replayer);
      replayer.on('resize', (dimensions) => {
        if (isDimensions(dimensions)) {
          return scaleReplayerToFitContainer(dimensions);
        }
      });
      replayer.play(startTime);
      return () => {
        replayer.destroy();
      };
    }
  }, [initialStart, replayData]);

  useEffect(() => {
    if (player && containerRef.current) {
      scaleReplayerToFitContainer({ width: player.iframe.offsetWidth, height: player.iframe.offsetHeight });
    }
  }, [width, height, player]);

  return (
    <PlayerContainer ref={fullscreenRef} data-testid="Replay Event Viewer">
      {replayerContainer}
      <ToolbarContainer>
        {player && (
          <ReplayViewerToolbar
            replayer={player}
            isFullScreen={isFullScreen}
            toggleFullScreen={toggle}
            eventMarkers={eventMarkers}
          />
        )}
      </ToolbarContainer>
    </PlayerContainer>
  );
};
