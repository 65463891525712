import React, { forwardRef } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { DEVICE_TYPE } from '@sprigShared/survey-common-constants';
import { PLATFORM_TYPES } from '@sprigShared/surveys';
import { Icon, colors, size, zIndex } from 'twig';

const DESKTOP_RADIUS = size(0.5);
const MOBILE_RADIUS = size(2);

const DeviceFrame = styled.div<{ isMobile: boolean; isMarketing?: boolean; hideBorder?: boolean }>`
  ${({ hideBorder }) => !hideBorder && `border: 4px solid ${colors.border.light};`}
  border-radius: ${({ isMobile }) => (isMobile ? MOBILE_RADIUS : DESKTOP_RADIUS)};
  margin: ${({ isMobile }) => (isMobile ? 'auto;' : '0;')};
  flex: ${({ isMobile }) => (isMobile ? '1 0 616px;' : '1 0 200px;')};
  ${({ isMobile }) =>
    isMobile &&
    `
    aspect-ratio: 303 / 616;
    min-height: 616px;
    max-height: 616px;
    min-width: 303px;
    @supports (aspect-ratio: 1) {
      max-height: 900px;
    }
    `}
  align-self: ${({ isMobile }) => (isMobile ? 'center' : 'stretch')};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${colors.background.primary};
  ${({ isMarketing }) => isMarketing && 'margin: 24px;'}
`;

const Footer = styled.div<{ isMobile: boolean }>`
  flex-grow: 0;
  width: 100%;
  border-top: ${({ isMobile }) => (isMobile ? `1px solid ${colors.border.light}` : 'none')};
  height: ${({ isMobile }) => (isMobile ? '51px' : '0')};
  flex-basis: ${({ isMobile }) => (isMobile ? '51px' : '0')};
`;

const MobileHeaderContainer = styled.div`
  position: relative;
  flex-grow: 0;
  width: 100%;
  background-color: ${colors.navy[500]};
  justify-content: center;
  height: 72px;
  flex-basis: 72px;
  display: flex;
  flex-direction: row;
`;

const MobileHeaderBar = styled.div`
  height: 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 50px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
  align-self: flex-end;
`;

const BrowserHeaderContainer = styled.div<{ isMobile: boolean }>`
  position: relative;
  flex-grow: 0;
  width: 100%;
  border-bottom: 1px solid ${colors.border.light};
  height: ${({ isMobile }) => (isMobile ? '72px' : '64px')};
  flex-basis: ${({ isMobile }) => (isMobile ? '72px' : '64px')};
  display: flex;
  flex-direction: row;
`;

const HeaderBar = styled.div<{ isMobile: boolean }>`
  flex-grow: 1;
  align-self: flex-end;
  height: ${({ isMobile }) => (isMobile ? '21px' : '24px')};
  background-color: ${colors.background.secondary};
  border-radius: 16px;
  flex-basis: 1px;
  margin-bottom: ${({ isMobile }) => (isMobile ? '15px' : '19px')};
  margin-right: ${({ isMobile }) => (isMobile ? '23px' : '12px')};
  margin-left: ${({ isMobile }) => (isMobile ? '23px' : '28px')};
`;

const ChildContainer = styled.div<{ isMobile?: boolean; bkgImg?: string; hasBkg: boolean }>`
  flex-grow: 1;
  width: 100%;
  flex-basis: 100px;
  position: relative;

  ${({ isMobile, hasBkg }) =>
    isMobile &&
    css`
      background-color: ${colors.navy[500]};
      &:after {
        display: block;
        content: ' ';
        position: absolute;
        top: ${hasBkg ? '0' : '20px'};
        bottom: ${hasBkg ? '0' : '20px'};
        left: ${hasBkg ? '0' : '20px'};
        right: ${hasBkg ? '0' : '20px'};
        border-radius: ${hasBkg ? '0' : '10px'};
        background-color: rgba(0, 0, 0, 0.1);
      }
    `}
  ${({ bkgImg, hasBkg }) =>
    hasBkg &&
    css`
      background-image: url(${bkgImg});
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

const OverlayContainer = styled.div`
  z-index: ${zIndex.modal};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const PreviewDesktopHeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 21px;
  flex-shrink: 1;
  margin-left: 18px;
  opacity: 1;
`;

const BottomButtonRow = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 10px;
`;

const HeaderIcon = styled(Icon)`
  height: 20px;
  width: 20px;
`;

export interface DeviceContainerProps {
  platform?: PLATFORM_TYPES;
  device: DEVICE_TYPE;
  children?: React.ReactNode;
  isMarketing?: boolean;
  backgroundImage?: string;
  className?: string;
  hideHeaderAndBorder?: boolean;
}

export const DeviceContainer = forwardRef(
  (
    {
      platform,
      device,
      children,
      isMarketing,
      backgroundImage,
      className,
      hideHeaderAndBorder = false,
    }: DeviceContainerProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const isMobile = device === DEVICE_TYPE.MOBILE;

    return (
      <DeviceFrame
        ref={ref}
        className={className}
        isMarketing={isMarketing}
        isMobile={isMobile}
        hideBorder={hideHeaderAndBorder}
      >
        {!hideHeaderAndBorder && <Header platform={platform} isMobile={isMobile} />}
        <ChildContainer
          bkgImg={backgroundImage}
          hasBkg={Boolean(platform === PLATFORM_TYPES.WEB || platform === PLATFORM_TYPES.MOBILE || backgroundImage)}
          isMobile={isMobile}
        >
          <OverlayContainer>{children}</OverlayContainer>
        </ChildContainer>
        <Footer isMobile={isMobile} />
      </DeviceFrame>
    );
  }
);

const PreviewDesktopHeaderButtons = () => (
  <PreviewDesktopHeaderButtonContainer>
    <BottomButtonRow>
      <HeaderIcon color={colors.border.light} src="arrow-left" height="20px" width="20px" />
      <HeaderIcon color={colors.border.light} src="arrow-right" />
      <HeaderIcon color={colors.border.light} src="redo" />
    </BottomButtonRow>
  </PreviewDesktopHeaderButtonContainer>
);

const PreviewBrowserHeader = ({ isMobile }: { isMobile: boolean }) => (
  <BrowserHeaderContainer isMobile={isMobile}>
    {!isMobile && <PreviewDesktopHeaderButtons />}
    <HeaderBar isMobile={isMobile} />
  </BrowserHeaderContainer>
);

const PreviewMobileHeader = () => (
  <MobileHeaderContainer>
    <MobileHeaderBar />
    <MobileHeaderBar />
  </MobileHeaderContainer>
);

const Header = ({ isMobile, platform }: { isMobile: boolean; platform?: PLATFORM_TYPES }) =>
  platform === PLATFORM_TYPES.MOBILE ? <PreviewMobileHeader /> : <PreviewBrowserHeader isMobile={isMobile} />;

export const BrowserFrame = ({ className, children }: { className?: string; children: React.ReactNode }) => (
  <BrowserFrameWrapper className={className}>
    <BrowserFrameToolbar>
      <Circle />
      <Circle />
      <Circle />
    </BrowserFrameToolbar>
    <FrameBody>{children}</FrameBody>
  </BrowserFrameWrapper>
);

const BrowserFrameWrapper = styled.div`
  background-color: ${colors.common.white};
  border-radius: ${size(1.5)};
  border: 2px solid ${colors.border.light};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
const BrowserFrameToolbar = styled.div`
  border-bottom: 2px solid ${colors.border.light};
  display: flex;
  gap: ${size(1.5)};
  padding: ${size(2)};
`;
const Circle = styled.div`
  background-color: ${colors.border.light};
  border-radius: 50%;
  height: 10px;
  width: 10px;
`;
const FrameBody = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  overflow: auto;
`;

export const MobileFrame = ({ children }: { children: React.ReactNode }) => (
  <MobileFrameWrapper data-testid="mobile-frame">
    <MobileFrameToolbar />
    <MobileFrameBody>{children}</MobileFrameBody>
  </MobileFrameWrapper>
);
const MobileFrameWrapper = styled.div`
  background-color: ${colors.common.white};
  border-radius: ${size(4.5)};
  border: 2px solid ${colors.border.light};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: auto;
`;
const MobileFrameToolbar = styled.div`
  border-bottom: 1px solid ${colors.border.light};
  padding: 30px 16px 20px;

  &:after {
    background-color: ${colors.border.light};
    border-radius: ${size(2)};
    content: '';
    display: block;
    height: ${size(2)};
    width: 100%;
  }
`;
const MobileFrameBody = styled(FrameBody)`
  background-color: ${colors.border.light};
`;
